export let dir = '/home/titanz';
let fs = {
  'home': {
    'titanz': {
      "vaultwarden.sh": 'docker run -d --name vaultwarden -e DOMAIN=https://vaultwarden.conorz.at -v /vw-data/:/data/ -e SIGNUPS_ALLOWED=false vaultwarden/server:latest',
      "hangover.png": '<img src="hangover.png" width="303" height="404">',
      ".hello": "check out https://www.twitch.tv/rwxrob",
      ".advanced": "check out https://www.twitch.tv/lastmiles and join his irc @libera.chat #blastwave",
      "resume.pdf": "https://conorz.at/resume.pdf",
      'f': {
        ".ad.txt": "https://conorz.at/f/ad.txt",
        "pov-date.jpg": '<img src="/f/pov-date.jpg" width="380" height="404">'
      },
      "gpg.asc": "https://conorz.at/gpg/",
      "email-fingerprint.txt": "CF70 A92F 24B4 BF66 C61A  2034 65EC 4C53 D564 614F",
      "repo-fingerprint.txt": "5A3D 0715 FEDE C4AB 5853  D4AB 7A1A B350 7D9B 734F",
      'badges': {},
      "dodge.jpg": '<img src="dodge.jpg" width="380" height="380">',
      "pet.gfp": '<iframe width="314" height="321" scrolling="no" src="https://gifypet.neocities.org/pet/pet.html?name=spronkle&dob=1662649707&gender=f&element=Air&pet=robot.gif&map=night.gif&background=1995.png&tablecolor=%233d3846&textcolor=black" frameborder="0"></iframe>',
      'characters': {
        'lukas.chr': `<div class="character-sheet">
<div class="character-art">
<b>
@  .................../...........,,.,,,,,,,,,,,,, <br>
@....................#&#%%.........,,,,,,,,,,,,,,, <br>
@...............,#(&&%&&@@@@#(*..,..,,,,,,,,,,,,,, <br>
@............*./&@@@@@@@@@@@@@@.....,,,,,,,,,,,,,, <br>
@............(&&&#(/##%%&&&&@@@&,..,,,,,,,,,,,,,,, <br>
@..........,,&%*,,,,,***//(((((%@&,,,,,,,,,,,,,,,, <br>
@...........%%*,,,,,***////(((((&&,,,.,,,,,,,,,,,, <br>
@.........,,%(,,,,,,**///((((((/#&(,,,,,,,,,,,,,,, <br>
@..........,,*,,***///((((((((((/&,,.,,,,,,,,,,,,, <br>
@.........,,,(*(%&&&%%((#%&&%%%#/%,,,,,,,,,,,,,,,, <br>
@.......,,,,,*,,*(##//,*(%&&%#((/#,,,,,,,******,,, <br>
@......,.,,,,.,,,,,,,,,*(((##((//***************** <br>
@......,.,,,,,*,,,*/*//(((#(((((***********/*///** <br>
@.....,,,,,,,,******/((#%&##((((*****///////////// <br>
@,,.,,,,,,,,,,,,//#(//#&%%##((#,,****///////////// <br>
@,,,,,,,,,,,,,&,,#///(######%#(@@@***///////////// <br>
@,,,,,,,,(&@@@@,,*/@%###%&@%#((@@@@@@@@/////////// <br>
@,,,/&@&@@@@@@@@(**//(#%%%###%@@@@@@@@@@@@@%////// <br>
@(&@@@@@@@@@@@@@@@@@/((###@@@@@@@@@@@@@@@@@@@@((/( <br>
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@(((</b>
</div>
<div class="character-desc">
  <b>Lukas</b> <b class="black">(he/his)</b><br>
  <br>
  <b>height:</b> 178cm<br>
  <b>birthday:</b> 1997, September 18th <b class="black">(25 years old)</b><br>
  <b>gender:</b> male<br>
  <b>sexuality:</b> hetero<br>
  <b>written in:</b> Bash<br>
  <b>alignment:</b> chaotic good<br>
  <p>Lukas is a guy who is able to get excited about pretty everything.<br> 
  Like everyone else he needs to sleep, eat food and chocolate, drink mate, whiskey and beers.<br>
  He is an allrounder what belongs to sys admin stuff and coding, but his knowledge is limited. Anyhow, his favorite thing to do is to listen music. He listens pretty everything, from oldies to modern music. At the moment his favorit music genre is punk rock.
  Currently he is focusing on feeding his github reposity to show other people his skills and to share his code to others. On the other hand, he doesn't lose his projects anymore.
  His favorite distro is fedora but he is using qubes which is also based on fedora.<br>
  Sometimes it happens that he is just overwhelmed and chaotic which is also his bad characteristic.</p>
  <b>history</b><br>
  <p>Lukas was first created for a roadtrip story, however he's now learning coding, sys admin stuff and drinking mate <a href="https://github.com/titanz18" target="_blank">\u2588\u2588\u2588\u2588\u2588\u2588\u2588\u2588\u2588\u2588\u2588\u2588</a>.</p>
  <b>references</b><br>
</div>
</div>`
      }
    },
    "nextcloud": {
      data: {
          titanz: {
              files: {
                  "joplin": "https://joplinapp.org/",
                  "keepass.kdbx": "nice try (˵ ͡° ͜ʖ ͡°˵)"
              },
              "index.html": "https://hub.docker.com/_/nextcloud"
          }
      }
  }
}
};

export function getFromAbsolutePath(p) {
  p = p.replace(/\/[^\s/]+\/\.\./, '');
  p = p.replace(/\/\.\//, '');
  p = p.replace(/\/\.$/, '');
  p = p.replace(/\/+/, '/');
  p = p.slice(1);
  let c = fs;
  let path = '';

  if (p.length === 0) {
    path = '/';
  } else {
    for (let v of p.split('/')) {
      c = c[v];
      path = path + '/' + v;
      if (!c) return ['none', null];
    }
  }

  if (typeof c === 'string') {
    return ['file', c, path];
  } else {
    return ['directory', path, Object.keys(c)];
  }
}

export function getFromPath(p) {
  if (p.startsWith('~')) p = '/home/titanz' + p.slice(1);
  if (p.startsWith('/')) return getFromAbsolutePath(p);
  return getFromAbsolutePath(dir + '/' + p);
}

export function getFilesInFS(o) {
  if (!o) o = fs;
  let n = 0;
  for (let k of Object.values(o)) {
    if (typeof k === 'string') n++;
    else n += getFilesInFS(k);
  }
  return n;
}

export function setDir(d) {
  dir = d;
}
