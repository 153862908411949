// hi! welcome to my humble little source code.
//
// you may be wondering why i've left source maps on - this is because i want the site's source code to be publically viewable if needed.
// however, putting up the source code on a git server would be a bit redundant since, well, you have a source code viewer in your browser!
// and it's a lot more convinient than having to maintain a git repository and update it every now and then
// oh and also my scripts suck lmao
//
// regardless, have fun looking around! don't pay too much mind to the b64 encoded strings :)

import 'modern-normalize';
import '@fontsource/comic-mono';
import './style.css';

import { isMobile, text, html, moveCursorToEnd, addCSS } from './util';
import { dir, getFromPath } from './fs';
import { executeCommand, setError, error } from './commands';
import { pushHistory, setCommandHistory, pushHistoryRaw, commandHistory } from './history';
import { initWebring } from './webring';

export let keyDebug = false;
export function setKeyDebug(b) {
  keyDebug = b;
}

let commandCache;

function updateLocation() {
  const location = document.querySelector('#commandline .directory');
  let fmtDir = dir.replace('/home/titanz', '~');
  if (fmtDir.endsWith('/') && fmtDir !== '/') fmtDir = fmtDir.slice(0, -1);
  location.innerText = fmtDir;
}

function updateHash(firstRun) {
  let path = document.location.hash.slice(1);
  if (path.endsWith('/') && path !== '/') path = path.slice(0, -1);
  let [type] = getFromPath(path);
  let commandRes;
  try {
    let commands = [];

    if (type === 'file') {
      commands.push(`cat ${path}`);
      commandRes = executeCommand(`cat ${path}`);
    } else if (type === 'directory') {
      commands.push(`cd ${path}`);
      executeCommand(`cd ${path}`);
      commands.push('ls');
      commandRes = executeCommand('ls');
    } else {
      throw new Error(`not a valid hash location ${path}`);
    }

    for (let c of commands) {
      pushHistory(c);
    }

    if (commandRes) document.querySelector('#history').appendChild(commandRes);
    updateLocation();

    if (firstRun) document.querySelector('#startup').remove();
  } catch(err) {
    console.error(err);
  }
}

function setFont(c) {
  const body = document.querySelector('body');

  for (let k of body.classList.values()) {
    if (k.startsWith('font-')) {
      body.classList.remove(k);
    }
  }

  window.localStorage.setItem('font', c || 'system');
  if (c) body.classList.add('font-' + c);
}

function updateFont() {
  if (window.localStorage.getItem('font')) {
    const font = window.localStorage.getItem('font');
    document.querySelectorAll('.font-select input').forEach(v => v.checked = false);
    document.querySelector(`.font-select #${font}`).checked = true;
    if (font === 'system') {
      setFont();
    } else {
      setFont(font);
    }
  }
}

let historyIndex = -1;

window.addEventListener('hashchange', () => updateHash());
document.addEventListener('DOMContentLoaded', function () {
  // js is on, add some stuff

  if (!isMobile) document.querySelector('#mobile-notice').style = 'display: none !important';

  document.querySelector('#tip').style = '';

  updateFont();

  document.querySelector('.font-select #ibm-vga').addEventListener('change', () => setFont('ibm-vga'));
  document.querySelector('.font-select #cozette').addEventListener('change', () => setFont('cozette'));
  document.querySelector('.font-select #comic-mono').addEventListener('change', () => setFont('comic-mono'));
  document.querySelector('.font-select #system').addEventListener('change', () => setFont());

  document.querySelector('.font-select-x').addEventListener('click', () => {document.querySelector('.font-select').classList.add('hidden');});

  setCommandHistory(JSON.parse(window.localStorage.getItem('commandHistory') || '[]') || []);
  //window.onmouseup = focus;

  const input = document.querySelector('#commandinput');

  const currentDate = new Date();
  if (currentDate.getDate() === 14 && currentDate.getMonth() === 8) {
    document.querySelector('#history').appendChild(html('<span class="rainbow">🍰 birthday :D</span>'));
  }

  if (document.location.hash) {
    updateHash(true);
  }

  input.addEventListener('keydown', (e) => {
    let code = e.keyCode;

    if (keyDebug) {
      document.querySelector('#history').appendChild(text(`${e.keyCode} - ${e.code}`));
    }

    if (code === 13) { // enter
      const command = input.value;
      if (command.trim().length !== 0) pushHistoryRaw(command);
      historyIndex = -1;

      if (commandHistory.length > 100) {
        commandHistory.shift();
      }

      let res;
      try {
        setError(false);
        res = executeCommand(command);
      } catch (err) {
        setError(true);
        console.error(err);
        res = html(`<span class="red">A JavaScript error occured running this command: ${err.message}</span><br><pre><b class="black">  ${(err.stack || '').split('\n').slice(0, -1).join('\n  ')}</b></pre>`);
      }

      if (!error) {
        document.querySelector('#tip').style = 'display: none !important';
        document.querySelector('#mobile-notice').style = 'display: none !important';
      }

      pushHistory();

      if (res) document.querySelector('#history').appendChild(res);

      const prompt = document.querySelector('#commandline .commandsymbol');
      if (error) {
        prompt.classList.add('red');
        prompt.classList.remove('green');
      } else {
        prompt.classList.remove('red');
        prompt.classList.add('green');
      }

      updateLocation();

      input.scrollIntoView();
    }

    if (commandHistory.length > 0) {
      if (code === 38) { // up
        if (historyIndex === -1) {
          commandCache = input.value;
          historyIndex = commandHistory.length - 1;
        } else if (historyIndex > 0) {
          historyIndex--;
        }
        input.value = commandHistory[historyIndex];
        setTimeout(() => moveCursorToEnd(input), 2);
      }
      if (code === 40) { // down
        if (historyIndex === -1) {
          // do nothing
        } else if (historyIndex < commandHistory.length - 1) {
          historyIndex++;
          input.value = commandHistory[historyIndex];
          setTimeout(() => moveCursorToEnd(input), 2);
        } else if (historyIndex === commandHistory.length - 1) {
          historyIndex = -1;
          input.value = commandCache;
          setTimeout(() => moveCursorToEnd(input), 2);
        }
      }
    }

    window.localStorage.setItem('commandHistory', JSON.stringify(commandHistory));
  });

  initWebring();
});

addCSS(`
.js-only {
  display: initial !important;
}
.js-off {
  display: none !important;
}
`);