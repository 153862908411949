import { setError } from '.';
import { text, html, escapeHTML } from '../util';
import { getFromPath, dir } from '../fs';
import { setComplete } from '../completion';

const badges = `
<div class="badges">
  <a href="https://kernel.org/" target="_blank" rel="noopener"><img src="badges/linux.gif" width="88" height="31" alt="kernel" title="download newest kernel!"></a>
  <a href="https://getfedora.org/" target="_blank" rel="noopener"><img src="badges/fedora.gif" width="88" height="31" alt="fedora" title="my favorite distro!"></a>
  <a href="https://kde.org" target="_blank" rel="noopener"><img src="badges/kde.gif" width="88" height="31" alt="kde" title="favorite de!"></a>
  <a href="https://nextcloud.com/" target="_blank" rel="noopener"><img src="badges/nextcloud.gif" width="88" height="31" alt="nextcloud" title="smooth self hosted cloud"></a>
  <a href="https://docs.joinmastodon.org/user/run-your-own/" target="_blank" rel="noopener"><img src="badges/masto.gif" width="88" height="31" alt="mastodon" title="selfhost your instance"></a>
  <img src="badges/lukasworld.gif" width="88" height="31" alt="lukas" title="found a "lukas world" gif"></a>
</div>
`;

export default (args) => {
  if (args.includes('--help')) {
    return text(`Usage: ls [OPTION]... [FILE]...
List information about the FILEs (the current directory by default).

Mandatory arguments to long options are mandatory for short options too.
-a, --all                  do not ignore entries starting with .
-A, --almost-all           do not list implied . and ..`);
  } else {
    let all = args.includes('-a');
    if (all) args = args.filter(i => i !== '-a');
    let almostAll = args.includes('-A');
    if (almostAll) args = args.filter(i => i !== '-A');

    // eslint-disable-next-line no-unused-vars
    let [type, fullPath, files] = getFromPath(args[0] || dir);
    if (!files) {
      setError(true);
      return text('ls: cannot access ' + args[0] + ': No such directory');
    } else if (type === 'file') {
      setError(true);
      return text('ls: cannot read file ' + args[0] + ': Not a directory');
    } else {
      if (fullPath === '/home/titanz/badges') {
        setComplete(10);
        return html(badges);
      } else {
        let f = files.map(f => [f, ...getFromPath(dir + '/' + f)]);
        let list = f.map(f => (f[1] === 'directory') ? (f[0] + '/') : f[0]);
        if (all) {
          list = ['.', '..', ...list];
        }
        if (!(all || almostAll)) list = list.filter(f => !f.startsWith('.'));
        return html(
          list
            .map(l => ({html: escapeHTML(l), text: l}))
            .map(l => `<a class="silentlink" onclick="document.querySelector('#commandinput').value = '${l.text.endsWith('/') || l.text.endsWith('.') ? 'cd' : 'cat'} ${l.text}'">${l.html}</a>`)
            .join('<br>')
        );
      }
    }
  }
};
