const LOADING_HTML = '<br><b class="black" id="webring-inner">loading...</b>';
let webringHTML = null;

export function initWebring() {
  document.querySelector('#webring').innerHTML = LOADING_HTML;

  let webring = document.querySelector('#webring-inner');
  if (webring) {
    let data = fetch('https://lavender.software/webring/data.json');
    data.then(res => res.json()).then(json => {
      let index = json.findIndex(l => l.id === 'oatmealine');
      let idxRight = (index + 1) % json.length;
      let idxLeft = (index - 1) % json.length;
      let left = json[idxLeft];
      let right = json[idxRight];
      webring.innerHTML = `<a href="https://the-morpheus.de/" rel="next noreferrer external">&lt; morpheus</a> / bot coder for Rene Fürst's discord server <a rel="external noreferrer"
      href="https://discord.gg/JCMzN3NmJP">Renes discord server</a> / <a href="https://www.renefuerst.eu/" rel="next noreferrer external">Rene &gt;</a>`;
      webringHTML = document.querySelector('#webring').innerHTML;
    });
  }
}

export function getWebring() {
  if (webringHTML)
    return webringHTML;

  const elem = document.querySelector('#webring');

  if (!elem) {
    return LOADING_HTML;
  } else {
    return elem.innerHTML;
  }
}