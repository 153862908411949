import { setComplete } from '../completion';
import { html } from '../util';

const youtube = [
  'YJSf5VpIOyE',
  's0c35RHsU',
  'Miyjsc3ccyk',
  'GMBKQF9_PB0',
  'VtKABQQUCYg',
];

export default () => {
  setComplete(11);
  let src = youtube[Math.floor(Math.random() * youtube.length)];
  return html(`<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/${src}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`);
};