import { text, html } from '../util';
import { setComplete } from '../completion';
import { getWebring } from '../webring';

const neofetchProperties = [
  {name: 'timezone', value: 'UTC+1'},
  {name: 'fav language', value: 'python'},
  {name: 'you lost', value: 'the game'},
  {name: 'favorite game character', value: 'chloe from life is strange'},
  {name: 'bot', value: 'true'},
  {name: 'preferred editor', value: 'helix'},
  {name: 'shell', value: 'zsh'},
  {name: 'preferred terminal', value: 'terminator'},
  {name: 'distance away from your current location', value: `${Math.floor((Math.random() + 0.2) * 10) / 10}km`},
  {name: 'did you know that zsh stands for', value: 'zhong shao'},
  {name: 'eternally', value: 'hyped'},
  {name: 'human', value: 'rights'},
  {name: 'resolution', value: `${window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth}x${window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight}`},
  {name: 'run ls -a', value: 'to see hidden files!'},
  {name: 'sus', value: 'false'},
  {name: 'useragent', value: navigator.userAgent},
  {name: 'brain cell count', value: '1'},
//  {name: 'please play ror2 with me', value: '<a target="_blank" href="https://steamcommunity.com/id/oatmealine/">https://steamcommunity.com/id/oatmealine/</a>'},
  {name: 'music collection size', value: '107.6GB, 4609 songs, 10 days, 18 hours and 42 minutes'},
  {name: 'xss', value: '<a href="javascript:alert(\'xss doesnt even make sense in this context. i made the website. theres no html thats fetched externally\')">click me</a>'},
  {name: 'idk', value: 'i\'m out of ideas'}
];

export default (args) => {
  if (args.includes('--help')) {
    return text(`Usage: neofetch [OPTION]...
Neofetch is a CLI system information tool written in BASH. Neofetch
displays information about your system next to an image, your OS logo,
or any ASCII file of your choice.

Options:
-v                         verbose (add more -vs for more verbosity, eg. -vv)
-s                         silent`);
  } else {
    let verbosity = args.filter(i => i.startsWith('-v')).map(i => i.length - 1).reduce((a, b) => a + b, 0)
        - args.filter(i => i.startsWith('-s')).map(i => i.length - 1).reduce((a, b) => a + b, 0);

    if (verbosity < -4) {
      setComplete(6);
      return html(`<b class="black">temma${' really'.repeat(-4 - verbosity - 1)} cute</b>`);
    }

    let extraProperties = [];
    if (verbosity > 0) {
      setComplete(5);
      if (verbosity > 2) setComplete(12);
      for (let i = 0; i < verbosity; i++) {
        extraProperties.push(neofetchProperties[i % neofetchProperties.length]);
      }
    }

    return html(`
<div class="neofetch h-card">
<img class="neofetch-img u-logo" src="me.png" width="310" height="310">
<!--- <b>
@  .................../...........,,.,,,,,,,,,,,,, <br>
@....................#&#%%.........,,,,,,,,,,,,,,, <br>
@...............,#(&&%&&@@@@#(*..,..,,,,,,,,,,,,,, <br>
@............*./&@@@@@@@@@@@@@@.....,,,,,,,,,,,,,, <br>
@............(&&&#(/##%%&&&&@@@&,..,,,,,,,,,,,,,,, <br>
@..........,,&%*,,,,,***//(((((%@&,,,,,,,,,,,,,,,, <br>
@...........%%*,,,,,***////(((((&&,,,.,,,,,,,,,,,, <br>
@.........,,%(,,,,,,**///((((((/#&(,,,,,,,,,,,,,,, <br>
@..........,,*,,***///((((((((((/&,,.,,,,,,,,,,,,, <br>
@.........,,,(*(%&&&%%((#%&&%%%#/%,,,,,,,,,,,,,,,, <br>
@.......,,,,,*,,*(##//,*(%&&%#((/#,,,,,,,******,,, <br>
@......,.,,,,.,,,,,,,,,*(((##((//***************** <br>
@......,.,,,,,*,,,*/*//(((#(((((***********/*///** <br>
@.....,,,,,,,,******/((#%&##((((*****///////////// <br>
@,,.,,,,,,,,,,,,//#(//#&%%##((#,,****///////////// <br>
@,,,,,,,,,,,,,&,,#///(######%#(@@@***///////////// <br>
@,,,,,,,,(&@@@@,,*/@%###%&@%#((@@@@@@@@/////////// <br>
@,,,/&@&@@@@@@@@(**//(#%%%###%@@@@@@@@@@@@@%////// <br>
@(&@@@@@@@@@@@@@@@@@/((###@@@@@@@@@@@@@@@@@@@@((/( <br>
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@(((</b>
--->
<div class="neofetch-text">
  <b class="magenta">titanz</b>@<b class="magenta">conorz.at</b><br>
  ----------------------------<br>
  <span class="p-note">
  hi i'm <b><a class="silentlink p-name u-url" href="https://conorz.at/">Lukas</a></b> (online known as <span class="p-nickname">titanz</span>)<br>
  i do whatever funny stuff i decide i want to do<br>
  </span>
  i'm a lot of things, too much to list here, but most notably:<br>
  <b>python</b>, <b>java</b> dev, <b>fedora</b> enjoyer, <b>sys admin</b>,<br>
  <b>pen tester</b>, <b>OPSEC and OSINT</b> interested<br>
  ${verbosity >= -3 ? '<br><b>pronouns:</b> he/his<br>' : ''}
  ${verbosity >= -2 ? `
  <b>links:</b>
  <a href="https://blog.conorz.at/" rel="me noreferrer" target="_blank">blog</a>
  <!-- coming soon <a href="https://git.conorz.at/titanz" rel="me noreferrer" target="_blank">git</a> -->
  <a href="https://www.youtube.com/@titanz18" rel="me noreferrer"
      target="_blank">youtube</a>
  <br>
  ` : ''}
  ${verbosity >= -1 ? `
  <b>contact:</b>
  <a href="mailto:titanz@conorz.at" rel="me" id="email"><span class="underline">email</span></a>
  <a id="discord"
    onclick="navigator.clipboard.writeText('titanz#4918'); this.classList.remove('copyable'); this.classList.add('copied');"
    onmouseover="this.classList.add('copyable'); this.classList.remove('copied')"><span class="underline">discord</span></a>
  <a href="https://matrix.to/#/@titanz:conorz.at" rel="me noreferrer" target="_blank">matrix</a>
  <br>
  ` : ''}
  ${extraProperties.map(p => `<b>${p.name}:</b> ${p.value}<br>`).join('')}
  ${verbosity >= 0 ? `
  <span id="webring">${getWebring()}</span><br><br>
  <b class="black">part of <a target="_blank" rel="noreferrer" href="https://metalab.at">metalab</a></b><br>
  ` : ''}
  <br>
  <pre><span class="black-bg">   </span><span class="red-bg">   </span><span class="green-bg">   </span><span class="yellow-bg">   </span><span class="blue-bg">   </span><span class="magenta-bg">   </span><span class="cyan-bg">   </span><span class="white-bg">   </span></pre>
  <pre style="margin-top: -0.2em"><span class="bright-black-bg">   </span><span class="bright-red-bg">   </span><span class="bright-green-bg">   </span><span class="bright-yellow-bg">   </span><span class="bright-blue-bg">   </span><span class="bright-magenta-bg">   </span><span class="bright-cyan-bg">   </span><span class="bright-white-bg">   </span></pre>
</div>
</div>
      `);
  }
};